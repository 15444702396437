import axios from 'axios'

const API = '/pqrs'

const PQRS = {
    listar(params){
        return axios(`${API}`,params)
    },
    listarFinalizadas(params){
        return axios(`${API}/finalizadas`,params)
    },
    getEstadisticas(){
        return axios(`${API}/estadisticas`)
    },
    getPqrsPorId(idPqrs){
        return axios(`${API}/mostrar/${idPqrs}`)
    },
    getComentariosPqr(idPqrs,params){
        return axios(`${API}/comentarios/${idPqrs}`,params)
    },
    crearComentarioPqr(params){
        return axios.post(`${API}/comentario`,params)
    },
    finalizarPqr(params){
        return axios.put(`${API}/finalizar`,params)
    },
    getResoluciones(params){
        return axios(`${API}/listar-grupos`,{params})
    },
    getPqrsVinculadas(idPqrs){
        return axios(`${API}/vinculadas/${idPqrs}`)
    },
    getResolucionXId(idGrupo){
        return axios(`${API}/grupo/${idGrupo}`)
    },
    buscarResoluciones(params){
        return axios(`${API}/buscar-grupo-filtros`,{params})
    },
    crearResolucion(params){
        return axios.post(`${API}/crear-grupo`,params)
    },
    vincularPqr(params){
        return axios.put(`${API}/vincular-grupo`,params)
    },
    eliminarResolucion(params){
        return axios.delete(`${API}/eliminar-grupo`,{params})
    },
    getComentariosGrupo(idGrupo,params){
        return axios(`${API}/comentarios-grupo/${idGrupo}`,{params})
    },
    eliminarComentarioGrupo(params){
        return axios.delete(`${API}/eliminar-comentario`,{params})
    },
    eliminarImagenGrupo(params){
        return axios.delete(`${API}/eliminar-imagen-grupo`,{params})
    },
    habilitarVigilante(payload){
        return axios.put(`${API}/habilitar-vigilante`,payload)
    }

}

export default PQRS
